import type { ImageLoader } from 'next/image';

const supportedExtensions: Record<string, string> = {
    jpg: 'jpg',
    jpeg: 'jpg',
    png: 'png',
};

export const s3ImageLoader: ImageLoader = ({ src, width }) => {
    const suffix = src.split('.').pop();
    const extension = (suffix && supportedExtensions[suffix]) ?? 'jpg';

    return `${src}:resize/${width}x.${extension}`;
};
